<template>
  <div v-loading="isLoading" class="page-container">
    <module-header title="基础家庭信息" />
    <div>
      <base-info />
    </div>
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import baseInfo from './template/baseInfo'
import { CommonMudule } from '@/store/modules/common'
import { fetchPensionFind } from '@/api/pension'
@Component({
  name: 'planFinancialAnalysis',
  components: {
    moduleHeader,
    baseInfo
  }
})
export default class extends Vue {
  isLoading = true
  created() {
    if (this.$route.query.type) {
      this.isLoading = false
    } else {
      this.fetchData()
    }
  }
  async fetchData() {
    const res = await fetchPensionFind({ customerId: CommonMudule.customerId })
    if (res.success && res.statusCode === 0) {
      if (res.data && res.data.retireAge) {
        this.$router.replace('/pension/planDetail')
      }
    }
    this.isLoading = false
  }
}
</script>
