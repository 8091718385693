








































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { OptionList } from '@/api/common'
import { getFinancialAnalysisNext, getFinancialAnalysisInfo, getMoneyEdu } from '@/api/articles'
import { CommonMudule } from '@/store/modules/common'
import formItem from '@/components/formItem/index.vue'
import moduleHeader from '@/components/moduleHeader/index.vue'
import handleBtn from '@/components/handleBtn/index.vue'
import { familyStatus, libilityListStatus } from '@/types/data'
import { CusTomer, CustomerMember, CustomerMemberChildren, CreatModel, OptArr } from '@/types/kyc'
import { cloneDeep } from 'lodash'
import { accDiv, accMul } from '@/utils/tools'

@Component({
  name: 'planBaseInfo',
  components: {
    formItem,
    moduleHeader,
    handleBtn
  }
})
export default class extends Vue {
 private creatModel:CreatModel = {
   customer: {
     id: '',
     customerName: '',
     familyStatus: '', // 家庭状况
     mateAge: '', // 配偶年龄
     children: '', // 孩子数量
     riskType: ''// 风险能力
   },
   customerMember: {
     age: '', // 年龄
     gender: '', // 性别
     nationId: '', // 国籍
     occupationId: '', // 职业
     inAmount: '',
     outAmount: '',
     inAmountPer: 0, // 年收入
     investAmountPer: 0 // 年支出
   },
   customerMemberChildren: [{
     memberName: '',
     age: '',
     gender: '',
     customerEduList: [{
       eduType: '',
       regionId: '',
       eduAmount: '',
       regionName: '',
       dicType: ''
     }, {
       eduType: '',
       regionId: '',
       eduAmount: '',
       regionName: '',
       dicType: ''
     }]
   }]
 }
 private labelPosition = 'right'
private sexItem: OptArr = [{
  label: '男',
  value: '0'
}, {
  label: '女',
  value: '1'
}]
 private defaultKey1 = {
   label: 'label',
   value: 'value'
 }
 private defaultKey2 = {
   label: 'dicName',
   value: 'id',
   key: 'index'
 }
 private defaultKey3 = {
   label: 'dicName',
   value: 'id',
   key: 'index'
 }
 private familyList = familyStatus
 private jobList = []
 private countryList = []
 private regionList = []
 private libilityList = libilityListStatus
// 绑定对象中的某个属性，只有当该属性发生变化时，触发该动作
@Watch('creatModel.customerMemberChildren', { immediate: false, deep: false })
 customerMemberChildren(newVal: any) {
   this.creatModel.customer.children = newVal.length
 }
created() {
  this.initOption()
  this.initData()
}
private async initData() {
  const res = await getFinancialAnalysisInfo({ customerId: CommonMudule.customerId })
  res.data.customerMemberChildren = res.data.customerMemberChildren && res.data.customerMemberChildren.length ? res.data.customerMemberChildren.map((item:any) => {
    item.gender = this.isNum(item.gender) ? item.gender.toString() : item.gender
    item.customerEduList.forEach((mt:any, index:any) => {
      if (mt.eduType === 'Undergraduate') {
        mt.eduTypeName = '本科'
      }
      if (mt.eduType === 'Master') {
        mt.eduTypeName = '研究生'
      }
      mt.regionObj = {
        id: mt.regionId,
        dicName: mt.regionName,
        index: mt.special
      }
    })
    return item
  }) : []
  res.data.customerMember.gender = this.isNum(res.data.customerMember.gender) ? res.data.customerMember.gender.toString() : undefined
  res.data.customerMember.inAmount = res.data.customerMember.inAmount ? accMul(res.data.customerMember.inAmount, 10000) : res.data.customerMember.inAmount
  res.data.customerMember.outAmount = res.data.customerMember.outAmount ? accMul(res.data.customerMember.outAmount, 10000) : res.data.customerMember.outAmount
  this.creatModel = res.data
}
isNum(val: any) {
  return typeof val === 'number' && !isNaN(val)
}
private async initOption() {
  const res = await Promise.all([
    OptionList({
      dicType: 'Occupation'
    }),
    OptionList({
      dicType: 'Nation'
    }),
    OptionList({
      dicType: 'Region'
    })
  ])

  this.jobList = res[0].data
  this.countryList = res[1].data
  this.regionList = res[2].data
}
private formatTooltip(val:any) {
  return parseInt(val) + '%'
}
private handleChange() {
}
private clickLabel() {
}
private async submit() { // 点击下一步
  const value = this.$validator(this.$refs.ValidateForm)
  if (value) return
  const param = cloneDeep(this.creatModel)
  param.customer.children = this.creatModel.customerMemberChildren.length
  param.customerMember.gender = Number(param.customerMember.gender)
  param.customerMemberChildren = param.customerMemberChildren.map((item) => {
    item.gender = Number(item.gender)
    item.customerEduList.forEach((mt:any, index:any) => {
      delete mt.regionObj
    })
    return item
  })
  param.customerMember.inAmount = param.customerMember.inAmount ? accDiv(Number(param.customerMember.inAmount), 10000) : param.customerMember.inAmount
  param.customerMember.outAmount = param.customerMember.outAmount ? accDiv(Number(param.customerMember.outAmount), 10000) : param.customerMember.outAmount
  const res = await getFinancialAnalysisNext(param)
  if (res.statusCode === 0 && res.data) {
    this.$router.push('/pension/typein')
    const { customer } = res.data
    window.sessionStorage.setItem('customerName', customer.customerName)
    CommonMudule.SET_USER_NAME(customer.customerName)
  } else {
    this.$message({
      message: res.errorMsg,
      type: 'error'
    })
  }
}
private addInfo() {
  const model = cloneDeep(this.creatModel.customerMemberChildren[0])
  this.creatModel.customerMemberChildren.push(model)
}
private removeInfo(item:object, index:number) {
  if (this.creatModel.customerMemberChildren.length === 1) {
    this.$message({
      message: '至少保留一个',
      type: 'info'
    })
    return
  }
  this.creatModel.customerMemberChildren.splice(index, 1)
}
private async changeSelect(item:any, index1:any, index2:any) {
  console.log('===val', item, index1, index2)
  // this.creatModel.customerMemberChildren.customerEduList
  const res = await getMoneyEdu({ eduType: item.eduType, regionId: item.regionObj.id })
  if (res.data) {
    this.creatModel.customerMemberChildren[index1].customerEduList[index2].regionId = item.regionObj.id
    this.creatModel.customerMemberChildren[index1].customerEduList[index2].regionName = item.regionObj.dicName
    this.creatModel.customerMemberChildren[index1].customerEduList[index2].dicType = item.regionObj.dicType
    this.creatModel.customerMemberChildren[index1].customerEduList[index2].eduAmount = res.data.eduAmount
  } else {
    this.$message({
      message: '请求失败',
      type: 'error'
    })
  }
}
}
